/* src/design-tokens.css */
:root {
    --color-primary: #0b49f3;
    --color-secondary: #e71111;
    --color-accent: #14f0a7;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --font-family-body: 'Inter', sans-serif;
    --font-family-heading: 'Poppins', sans-serif;
  }
  